export const environment = {
  address: {
    latitude: 38.0060267750586,
    longitude: 23.764375286504798,
    name: '28is Oktovriou 11',
    zipCode: 'Psichiko 154 52'
  },
  apiURL: 'https://dev.capitarch.com',
  appVersion: require('../../package.json').version,
  background: 'capitarch-background.png',
  logo: 'capitarch-logo.png',
  name: 'Capitarch',
  production: true,
  sentryDSN: 'https://3434ab7359f1425b8982cbd45d069ae9@o4508110298284032.ingest.de.sentry.io/4508110300250192',
  sentryEnvironment: 'capitarch-development'
};
