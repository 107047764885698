{
  "name": "capitarch-client",
  "version": "1.0.0",
  "scripts": {
    "build:aero-production": "ng build --configuration aero-production",
    "build:capitarch-development": "ng build --configuration capitarch-development",
    "build:capitarch-production": "ng build --configuration capitarch-production",
    "build:capitarch-staging": "ng build --configuration capitarch-staging",
    "start": "ng serve --port 3001 --open"
  },
  "private": true,
  "dependencies": {
    "@angular-material-components/datetime-picker": "^16.0.1",
    "@angular-material-components/moment-adapter": "^16.0.1",
    "@angular/animations": "^18.2.11",
    "@angular/cdk": "^18.2.12",
    "@angular/common": "^18.2.11",
    "@angular/compiler": "^18.2.11",
    "@angular/core": "^18.2.11",
    "@angular/forms": "^18.2.11",
    "@angular/google-maps": "^18.2.13",
    "@angular/material": "^18.2.12",
    "@angular/platform-browser": "^18.2.11",
    "@angular/platform-browser-dynamic": "^18.2.11",
    "@angular/router": "^18.2.11",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "@sentry/angular": "^8.37.1",
    "apexcharts": "^4.0.0",
    "dayjs": "^1.11.13",
    "moment": "^2.30.1",
    "ng-apexcharts": "^1.12.0",
    "ngx-mask": "^18.0.3",
    "ngx-mat-select-search": "^7.0.8",
    "rxjs": "~7.8.0",
    "socket.io-client": "^4.8.1",
    "tslib": "^2.8.1",
    "xlsx": "^0.18.5",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular/cli": "^18.2.11",
    "@angular/compiler-cli": "^18.2.11",
    "@types/jasmine": "^5.1.4",
    "jasmine-core": "^5.4.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "^5.5.4"
  }
}
